<template>
  <v-card>
    <v-container>
      <v-card-title class="px-0 primary--text">Agente de cuenta</v-card-title>
      <v-row>
        <v-col cols="12" sm="2" class="d-flex py-0">
          <p class="label">Código:</p>
          <strong class="ml-2">{{ comprobante.agectaId }}</strong>
        </v-col>
        <v-col cols="12" sm="4" class="d-flex py-0">
          <p class="label">Nombre:</p>
          <strong class="ml-2">{{ comprobante.agectaNombre }}</strong>
        </v-col>
        <v-col cols="12" sm="3" class="d-flex py-0">
          <p class="label">Cond. IVA:</p>
          <strong class="ml-2">{{ comprobante.ivaNom }}</strong>
        </v-col>
        <v-col cols="12" sm="3" class="d-flex py-0">
          <p class="label">CUIT:</p>
          <strong class="ml-2">{{ comprobante.agectaCuit }}</strong>
        </v-col>
      </v-row>
      <v-row class="grey lighten-4">
        <v-col cols="12" sm="5" class="d-flex py-0">
          <p class="label">Tipo de comprobante:</p>
          <strong class="ml-2">{{ comprobante.tipoComprobante }}</strong>
        </v-col>
        <v-col cols="12" sm="2" class="d-flex py-0">
          <p class="label">N°:</p>
          <strong class="ml-2">{{ comprobante.compSucNro }}</strong>
        </v-col>
        <v-col cols="12" sm="2" class="d-flex py-0">
          <p class="label">Fecha:</p>
          <strong class="ml-2">{{ comprobante.fecha }}</strong>
        </v-col>
        <v-col cols="12" sm="3" class="d-flex py-0">
          <p class="label">Fecha de Vto:</p>
          <strong class="ml-2">{{ comprobante.fechaVencimiento }}</strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="d-flex py-0">
          <p class="label">Convenio:</p>
          <strong class="ml-2">{{ comprobante.convenio }}</strong>
        </v-col>
        <v-col cols="12" sm="4" class="d-flex py-0">
          <p class="label">Período:</p>
          <strong class="ml-2">{{ comprobante.periodo }}</strong>
        </v-col>
        <v-col cols="12" sm="4" class="d-flex py-0">
          <p class="label">Cajero:</p>
          <strong class="ml-2">{{ comprobante.caja }}</strong>
        </v-col>
      </v-row>
      <v-row class="grey lighten-4">
        <v-col cols="12" class="d-flex py-0">
          <p class="label">Observaciones:</p>
          <strong class="ml-2">{{ comprobante.compObs }}</strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="comprobante.tablaCompItems"
            :hide-default-footer="true"
            class="elevation-1"
            dense
          >
            <template v-slot:[`item.total`]="{ item }">
              <span>{{ item.total }}</span>
            </template>
            <template v-slot:[`item.iva`]="{ item }">
              <span>{{ item.iva }}</span>
            </template>
            <template v-slot:[`item.neto`]="{ item }">
              <span>{{ item.neto }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headersAgente"
            :items="comprobante.tablaFPago"
            class="elevation-1"
            :hide-default-footer="true"
            dense
          >
            <template v-slot:[`item.importe`]="{ item }">
              <span>{{ item.importe }}</span>
            </template></v-data-table
          >
        </v-col>
      </v-row>
      <v-row class="justify-end">
        <v-col cols="12" sm="8" class="d-flex py-0">
          <p class="label">Numero de CAE:</p>
          <strong class="ml-2 mb-0">{{ comprobante.caeNro }}</strong>
        </v-col>
        <v-col cols="12" sm="4" class="d-flex py-0 grey lighten-4">
          <p class="label">Total:</p>
          <strong class="ml-2 mb-0">{{ comprobante.compTotal }}</strong>
        </v-col>
      </v-row>
      <v-row class="justify-end">
        <v-col cols="12" sm="8" class="d-flex py-0">
          <p class="label">Fecha de Vto. CAE:</p>
          <strong class="ml-2 mb-0">{{ comprobante.caeFecVen }}</strong>
        </v-col>
        <v-col cols="12" sm="4" class="d-flex py-0">
          <p class="label" v-if="!esCancelatorio && !sinRelacionar">
            Cancelado:
          </p>
          <strong class="ml-2 mb-0" v-if="!esCancelatorio && !sinRelacionar">{{
            comprobante.cancelado
          }}</strong>
        </v-col>
      </v-row>
      <v-row class="justify-end">
        <v-col cols="12" sm="4" class="d-flex py-0 grey lighten-4">
          <p class="label" v-if="!esCancelatorio && !sinRelacionar">Saldo:</p>
          <strong class="ml-2 mb-0" v-if="!esCancelatorio && !sinRelacionar">{{
            comprobante.saldoTotal
          }}</strong>
        </v-col>
      </v-row>
      <v-card-actions class="justify-end">
        <v-btn outlined dense @click="closeModal">Cerrar</v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";

export default {
  name: "DetalleComprobante",
  props: ["compId", "esCancelatorio", "sinRelacionar"],
  components: {
    PageHeader
  },
  data() {
    return {
      calendarIcon: enums.icons.CALENDAR,
      rules: rules,
      headers: [
        { text: "Concepto", align: "start", value: "concNombre" },
        { text: "Período", align: "start", value: "periodo" },
        { text: "Neto", align: "end", value: "neto" },
        { text: "IVA", align: "end", value: "iva" },
        { text: "Total", align: "end", value: "total" }
      ],
      headersAgente: [
        { text: "Forma Pago", align: "start", value: "nombre" },
        { text: "Entidad", align: "start", value: "entfinNombre" },
        { text: "Importe", align: "end", value: "importe" },
        { text: "CBU", align: "start", value: "cbu" },
        { text: "N° Tarjeta/cheque", align: "start", value: "numero" },
        { text: "N° Auto.", align: "start", value: "nroAuto" },
        { text: "Banco", align: "start", value: "bancoNombre" },
        { text: "Fecha emisión", align: "start", value: "pfFecEmision" }
      ],
      comprobante: {}
    };
  },
  created() {
    this.loadComprobante();
  },
  methods: {
    ...mapActions({
      getComprobanteById: "devengamientos/getComprobanteById"
    }),
    async loadComprobante() {
      const response = await this.getComprobanteById({ compId: this.compId });
      this.comprobante = response;
    },
    closeModal() {
      this.$emit("closeModalComprobante");
    }
  }
};
</script>

<style scoped>
.label {
  opacity: 0.7;
}
</style>
